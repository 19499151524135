// Importação de componentes e React
import React, { useEffect, useState } from "react";

// importação de estilização
import style from "./bodyReportBody.module.css";

// Importação de configuração manual
import localeText from "../../config/localeText";
import API_ROUTES from "../../config/endpoints";

// Importação de biblioteca de terceiros
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import * as locales from "@mui/material/locale";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

function BodyReportBody() {
  // constante de input de dados
  const [people, setPeople] = useState([]);

  // constante de manipulação de elementos
  const [loading, setLoading] = useState(true);

  // constante de linguagem do datagrid
  const theme = useTheme();

  // Função que captura usuarios do banco
  const fetchPeople = async () => {
    try {
      const response = await axios.get(API_ROUTES.ALL_COMUNICATION);
      const data = response.data;

      const activeData = data.filter((person) => person.active);

      const mappedData = activeData.map((person, index) => ({
        id: index,
        name: person.recipient.contact_info.name,
        element: person.recipient.sector.name,
        contact: person.recipient.contact_info.phone,
        pwa: person.timestamp.date + " " + person.timestamp.time,
        sms: person.timestamp.date + " " + person.timestamp.time,
        email: person.timestamp.date + " " + person.timestamp.time,
        whatsapp: person.timestamp.date + " " + person.timestamp.time,
        confirmation: person.confirmation.date + " " + person.confirmation.time,
      }));

      setPeople(mappedData);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar pessoas:", error);
      setLoading(false); // Mesmo em caso de erro, marca o carregamento como concluído
    }
  };

  // Captura usuarios do banco
  useEffect(() => {
    fetchPeople();
  }, []);

  // Colunas do datagrid
  const columns = [
    { field: "name", headerName: "Nome", width: 220 },
    { field: "element", headerName: "Elemento de Notificação", width: 210 },
    { field: "contact", headerName: "Contato", width: 210 },
    { field: "pwa", headerName: "PWA", width: 210 },
    { field: "sms", headerName: "SMS", width: 210 },
    { field: "email", headerName: "Email", width: 210 },
    { field: "whatsapp", headerName: "Whatsapp", width: 210 },
    { field: "confirmation", headerName: "Confirmação", width: 210 },
  ];

  // Tradução do datagrid
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales["ptBR"]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ["ptBR", theme]
  );

  return (
    <div className={style["App"]}>
      {/* Renderização de Datagrid */}
      <div className={style["container-grid"]}>
        {!loading && (
          <Box sx={{ width: "100%", height: "100%" }}>
            <ThemeProvider theme={themeWithLocale}>
              <DataGrid
                sx={{
                  width: "87.5vw",
                  height: "84.9vh",
                  backgroundColor: "white",
                  fontSize: "0.7vw",
                }}
                rows={people}
                columns={columns}
                localeText={localeText}
                pageSize={15}
                paginationMode="client"
                pagination={true}
                location={"ptBR"}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
              />
            </ThemeProvider>
          </Box>
        )}
      </div>

      {/* Renderização de Carregamento de pagina */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ToastContainer />
    </div>
  );
}

export default BodyReportBody;
