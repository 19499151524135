const API_BASE_URL = "https://mcs.sipremo.com/api";
// const API_BASE_URL = "http://localhost:8000";

const API_ROUTES = {
  // Exemplo de rota com parâmetro dinâmico
  USER_BY_USERNAME: (username) => `${API_BASE_URL}/user/user?username=${username}`,
  PEOPLE: `${API_BASE_URL}/user/all_users`,
  PEOPLE_EFNI: `${API_BASE_URL}/user/all_users_efni`,
  PEOPLE_APRESENTACAO: `${API_BASE_URL}/user/all_users_teste_apresentacao`,
  DELETE_PEOPLE: (username) =>
    `${API_BASE_URL}/user/delete_user?username=${username}`,
  DELETE_PEOPLE_EFNI: (username) =>
    `${API_BASE_URL}/user/delete_user_efni?username=${username}`,
  DELETE_PEOPLE_APRESENTACAO: (username) =>
    `${API_BASE_URL}/user/delete_user_teste_apresentacao?username=${username}`,
  ALTERNATE_PEOPLE: (username) =>
    `${API_BASE_URL}/user/alter_user?username=${username}`,
  ALTERNATE_PEOPLE_EFNI: (username) =>
    `${API_BASE_URL}/user/alter_user_efni?username=${username}`,
  ALTERNATE_PEOPLE_APRESENTACAO: (username) =>
    `${API_BASE_URL}/user/alter_user_teste_apresentacao?username=${username}`,
  CREATE_PEOPLE: `${API_BASE_URL}/user/create_user`,
  CREATE_PEOPLE_EFNI: `${API_BASE_URL}/user/create_user_efni`,
  CREATE_PEOPLE_APRESENTACAO: `${API_BASE_URL}/user/create_user_teste_apresentacao`,
  ALL_COMUNICATION: `${API_BASE_URL}/operacao/get_all_comunication`,
  ALL_COMUNICATION_APRESENTACAO: `${API_BASE_URL}/operacao/get_all_comunication_APRESENTACAO`,
  GET_USERS_GROUP_BY_GROUP: `${API_BASE_URL}/operacao/get_all_users_response_for_group`,
  GET_USERS_GROUP_BY_GROUP_APRESENTACAO: `${API_BASE_URL}/operacao/get_all_users_response_for_group_APRESENTACAO`,
  GET_REGISTRY_CONFIRMATION_PER_MINUTE: `${API_BASE_URL}/operacao/get_all_communication_registrys_for_ultimate_hour`,
  GET_REGISTRY_CONFIRMATION_PER_MINUTE_APRESENTACAO: `${API_BASE_URL}/operacao/get_all_communication_registrys_for_ultimate_hour_APRESENTACAO`,
  POST_MESSAGE_APRESENTACAO: `${API_BASE_URL}/operacao/send_mensage_teste_apresentacao?token=e19c1c2f4b7e6d8f3a9b5c2e1d8f4b6e7a9c5f2d3e4b7a8c6f3e2d1b9a5f8c4d3e7a9b6c1d5e8f2b3a4c7e6d9f1b5a2c8f4e7d3`,
  GET_ALL_USERS_BY_AREA: `${API_BASE_URL}/operacao/get_all_users_by_area`,
};

export default API_ROUTES;
