import React from "react";

import style from "./informations.module.css";
import PersonIcon from "@mui/icons-material/Person";

function InformationDiv({ number, titleNumber, subTitle, percentual, icon }) {
  return (
    <div className={style["App"]}>
      <div className={style["number-users"]}>
        <text className={style["title"]}>
          <div className={style["iconName"]}>
            {icon}
          </div>
          {titleNumber}
        </text>

        <div className={style["divisao"]}>
          <text className={style["number"]}>{number}</text>
          <div className={style["subtitle"]}>
            <text>{percentual}%</text>
            <text>{subTitle}</text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformationDiv;
