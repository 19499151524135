import { useState } from "react";
import style from "./navBarLeft.module.css";
import logoSipremo from "../../images/SipremoSemFundopng.png";

import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import IosShareIcon from '@mui/icons-material/IosShare';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

function NavBarLeft({ page }) {
  const [typePage, setTypePage] = useState("people");
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true); // Estado para o menu colapsado

  const use = localStorage.getItem("user");
  const userData = JSON.parse(use);  // Atribuir o JSON parse a uma constante para reutilização

  const navigation = useNavigate();

  const handleChangePageToLogin = () => {
    navigation("/");
  };

  const handleChangePage = (newPage, newTypePage) => {
    page(newPage);
    setTypePage(newTypePage);
  };

  const toggleMenu = () => {
    setIsMenuCollapsed(!isMenuCollapsed); 
  };

  return (
    <div className={`${style["App"]} `}>
      <div className={style["menuIcon"]} onClick={toggleMenu}>
       
        <div className={style["menuText"]}>SIPREMO</div>
      </div>
      <div className={style["menuIconPersonContent"]}>
        <div
          className={typePage === "people" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("people", "people")}
        >
          <PeopleOutlineIcon style={{
            width: "1.2vw"
          }}/>
          <div className={style["name-option"]}>Usuários</div>
        </div>
        <div
          className={typePage === "report" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("report", "report")}
        >
          <ContentPasteSearchIcon style={{
            width: "1.2vw"
          }}/>
          <div className={style["name-option"]}>Relatório</div>
        </div>
        <div
          className={typePage === "data" ? style["menuIconSelected"] : style["menuIconSelect"]}
          onClick={() => handleChangePage("data", "data")}
        >
          <DashboardOutlinedIcon style={{
            width: "1.2vw"
          }}/>
          <div className={style["name-option"]}>Dashboard</div>
        </div>
        {userData.username === "l0.h0" && (
          <>
          <div className={style["divisor"]}/>
            <div
              className={
                typePage === "apresentacao"
                  ? style["menuIconSelectedApresentacao"]
                  : style["menuIconSelectApresentacao"]
              }
              onClick={() => handleChangePage("apresentacao", "apresentacao")}
            >
              <IosShareIcon style={{
                width: "1.2vw"
              }}/>
              <div className={style["name-option"]}>Teste</div>
            </div>
            <div
              className={
                typePage === "relatorioApresentacao"
                  ? style["menuIconSelectedApresentacao"]
                  : style["menuIconSelectApresentacao"]
              }
              onClick={() => handleChangePage("relatorioApresentacao", "relatorioApresentacao")}
            >
              <DashboardOutlinedIcon style={{
                width: "1.2vw"
              }}/>
              <div className={style["name-option"]}>Dashboard Teste</div>
            </div>
          </>
        )}
      </div>
      <div className={style["menuIconExit"]} onClick={handleChangePageToLogin}>
        <LogoutIcon style={{
                width: "1.2vw"
              }}/>
        <div>Sair</div>
      </div>
    </div>
  );
}

export default NavBarLeft;