import React, { useState, useCallback, useEffect } from "react";
import {
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceArea,
  BarChart,
  Bar,
  Legend,
  Cell,
} from "recharts";
import style from "./BodyDataBody.module.css";
import InformationDiv from "./informations/informations";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import API_ROUTES from "../../config/endpoints";
import { toast, ToastContainer } from "react-toastify";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function BodyDataBody({ usuario, bodyRequest }) {
  const [refAreaLeft, setRefAreaLeft] = useState(null); // Inicialize como null
  const [refAreaRight, setRefAreaRight] = useState(null); // Inicialize como null
  const [zoomState, setZoomState] = useState({
    left: "dataMin",
    right: "dataMax",
    top: "dataMax+1",
    bottom: "dataMin",
    top2: "dataMax+2",
    bottom2: "dataMin+5",
  });
  const [qtdePeople, setQtdePeople] = useState(0);
  const [qtdeIntern, setQtdeIntern] = useState(0);
  const [qtdeExtern, setQtdeExtern] = useState(0);
  const [percentualInternos, setPercentualInternos] = useState(0);
  const [percentualExternos, setPercentualExternos] = useState(0);
  const [percentualConfirmacao, setPercentualConfirmacao] = useState(0);
  const [qtdeConfirmated, setQtdeConfirmated] = useState(0);
  const [maiorRegistry, setMaiorRegistry] = useState(0);
  const [dataInicio, setDataInicio] = useState("");
  const [horaInicio, setHoraInicio] = useState("");
  const [horaFinal, setHoraFinal] = useState("");

  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [allUsersByArea, setAllUsersByArea] = useState([]);

  const notifyError = (text) => toast.error(text);

  const getInformations = async () => {
    await axios
      .get(API_ROUTES.ALL_COMUNICATION)
      .then(({ data }) => {
        let intern = 0;
        let extern = 0;
        let confirmation = 0;

        setQtdePeople(data.length);
        data.map((dt) => {
          if (dt.recipient.sector.hierarchy == 2) {
            extern += 1;
          } else {
            intern += 1;
          }

          if (dt.read) {
            confirmation += 1;
          }
        });

        setQtdeExtern(extern);
        setQtdeIntern(intern);
        setQtdeConfirmated(confirmation);

        const total = intern + extern;
        const percentualInternos = (intern / total) * 100;
        const percentualExternos = (extern / total) * 100;
        const percentualConfirmacao = (confirmation / total) * 100;

        setPercentualInternos(percentualInternos.toFixed(2));
        setPercentualExternos(percentualExternos.toFixed(2));
        setPercentualConfirmacao(percentualConfirmacao.toFixed(2));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResponseForGroup = async () => {
    await axios
      .get(API_ROUTES.GET_USERS_GROUP_BY_GROUP)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResponseForConfirmations = async () => {
    await axios
      .get(API_ROUTES.GET_REGISTRY_CONFIRMATION_PER_MINUTE)
      .then(({ data }) => {
        const dataInicial = data[0].datahora.split(" ")[0];
        const horaInicial = data[0].datahora.split(" ")[1];
        const horaFinal = (
          parseInt(data[0].datahora.split(" ")[1].split(":")[0]) + 1
        ).toString();
        const minuto = data[0].datahora.split(" ")[1].split(":")[1];
        const segundo = data[0].datahora.split(" ")[1].split(":")[2];

        setInitialData(data);
        setDataInicio(dataInicial);
        setHoraInicio(horaInicial);
        setHoraFinal(`${horaFinal}:${minuto}:${segundo}`);

        data.map((dt) => {
          if (maiorRegistry < dt.confirmacao) {
            setMaiorRegistry(dt.confirmacao + 2);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { datahora, confirmacao, pessoas } = payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "1vw",
            border: "1px solid #ccc",
          }}
        >
          <p style={{ fontSize: "0.6vw" }}>{`Período: ${datahora}`}</p>
          <p
            style={{ fontSize: "0.6vw" }}
          >{`Total de Confirmações: ${confirmacao}`}</p>
          {pessoas && pessoas.length > 0 ? (
            <TableContainer component={Paper} sx={{ marginTop: "25px" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "0.6vw" }}>Nome</TableCell>
                  <TableCell align="right" style={{ fontSize: "0.6vw" }}>
                    Horário de Confirmação
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pessoas.map((p, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontSize: "0.6vw" }}
                    >
                      {p.nome}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: "0.6vw" }}>
                      {p.confirmationHour}
                    </TableCell>
                  </TableRow>

                  // <p className="envios">{`${index + 1}° - ${p}`}</p>
                ))}
              </TableBody>
            </TableContainer>
          ) : (
            <p style={{ fontSize: "0.6vw" }}>
              Ninguém Confirmou no Período Indicado
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const getAxisYDomain = useCallback(
    (from, to, ref, offset) => {
      const refData = initialData.slice(from - 1, to);
      let [bottom, top] = [refData[0][ref], refData[0][ref]];
      refData.forEach((d) => {
        if (d[ref] > top) top = d[ref];
        if (d[ref] < bottom) bottom = d[ref];
      });

      return [0, (top | 0) + offset];
    },
    [initialData]
  );

  const zoom = useCallback(() => {
    try {
      if (refAreaLeft === null || refAreaRight === null) return;

      if (refAreaLeft > refAreaRight) {
        [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
      }

      const [bottom, top] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "envios",
        0
      );
      const [bottom2, top2] = getAxisYDomain(
        refAreaLeft,
        refAreaRight,
        "envios",
        1
      );

      setZoomState({
        left: refAreaLeft,
        right: refAreaRight,
        bottom,
        top,
        bottom2,
        top2, // Aqui o topo terá o valor máximo + 1
      });
      setRefAreaLeft(null);
      setRefAreaRight(null);
    } catch {
      notifyError("Erro ao realizar o zoom");
    }
  }, [refAreaLeft, refAreaRight, getAxisYDomain]);

  const zoomOut = useCallback(() => {
    setZoomState({
      left: "dataMin",
      right: "dataMax",
      top: "dataMax",
      bottom: "dataMin",
      top2: "dataMax+2",
      bottom2: "dataMin+5",
    });
    setRefAreaLeft(null);
    setRefAreaRight(null);
  }, []);

  // get_all_users_by_area

  const getAllUsersByArea = async () => {
    await axios
      .get(API_ROUTES.GET_ALL_USERS_BY_AREA)
      .then(({ data }) => {
        setAllUsersByArea(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getResponseForGroup();
    getInformations();
    getResponseForConfirmations();
    getAllUsersByArea();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getResponseForGroup();
      getInformations();
      getResponseForConfirmations();
    }, 60000);
  }, []);

  const CustomTooltipBar = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const people = payload.find((p) => p.dataKey === "people");
      const grp = people.payload.name;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p style={{ fontSize: "0.6vw" }}>{`${
            grp === "2" ? "Externos" : "Internos"
          }`}</p>
          <p style={{ fontSize: "0.6vw" }}>{`${people.value}% respondeu`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={style["App"]}>
      <div className={style["title-module"]}>
        Painel de Inteligência Notificações PAEBM
      </div>
      <div className={style["division"]}>
        <div>
          <div className={style["grid-info"]}>
            <div className={style["number-users"]}>
              <text className={style["title"]}>
                <div className={style["iconName"]}>
                  <ArrowCircleUpOutlinedIcon style={{ width: "1vw"}}/>
                </div>
                Qtde. de Enviados
              </text>
              <text className={style["number"]}>
                {qtdePeople}
                <text className={style["subtitle"]}>Agentes</text>
              </text>
            </div>

            <InformationDiv
              number={qtdeIntern}
              titleNumber={"Qtde. Internos"}
              percentual={percentualInternos}
              subTitle="Agentes"
              icon={<AccountCircleOutlinedIcon style={{color: "#595959", width: "1vw"}}/>}
            />
            <InformationDiv
              number={qtdeExtern}
              titleNumber={"Qtde. Externos"}
              percentual={percentualExternos}
              subTitle="Agentes"
              icon={<AccountCircleOutlinedIcon style={{color: "#595959", width: "1vw"}}/>}
            />
            <InformationDiv
              number={qtdeConfirmated}
              titleNumber={"Qtde. de Confirmações"}
              percentual={percentualConfirmacao}
              subTitle="Agentes"
              icon={<CheckCircleOutlinedIcon style={{color: "#595959", width: "1vw"}}/>}
            />
          </div>

          <div className={style["container-grafics"]}>
            <div className={style["container-grafic-line"]}>
              <text className={style["title-white"]}>
                Frequência de Confirmação
              </text>
              <div
                className="highlight-bar-charts"
                style={{
                  userSelect: "none",
                  width: "100%",
                  display: "flex",
                  height: "20vh",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={1500}
                    height={400}
                    data={initialData}
                    onMouseDown={(e) => {
                      if (e) setRefAreaLeft(e.activeLabel);
                    }}
                    onMouseMove={(e) => {
                      if (refAreaLeft) setRefAreaRight(e.activeLabel);
                    }}
                    onMouseUp={zoom}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      allowDataOverflow
                      dataKey="id"
                      domain={[zoomState.left, zoomState.right]}
                      type="number"
                      tickFormatter={(id) => {
                        const item = initialData.find((d) => d.id === id);
                        return item ? item.legend : id;
                      }}
                      tick={{ style: { fontSize: "0.6vw" } }}
                    />
                    <YAxis
                      allowDataOverflow
                      domain={[zoomState.bottom, zoomState.top]}
                      type="number"
                      yAxisId="1"
                      tick={{ style: { fontSize: "0.6vw" } }}
                    />
                    <YAxis
                      orientation="right"
                      allowDataOverflow
                      domain={[zoomState.bottom2, zoomState.top2]}
                      type="number"
                      yAxisId="2"
                      tick={{ style: { fontSize: "0.6vw" } }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      yAxisId="2"
                      type="natural"
                      dataKey="confirmacao"
                      stroke="#8947F4"
                      animationDuration={300}
                    />
                    {refAreaLeft && refAreaRight ? (
                      <ReferenceArea
                        yAxisId="1"
                        x1={refAreaLeft}
                        x2={refAreaRight}
                        strokeOpacity={0.3}
                      />
                    ) : null}
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <button type="button" className={style["btn"]} onClick={zoomOut}>
                Remover Zoom
              </button>
            </div>
          </div>

          <div className={style["container-grafics-area"]}>
            <div className={style["container-grafic"]}>
              <text className={style["title-white"]}>Confirmação por Área</text>
              <div className={style["container-grafic-area"]}>
                {allUsersByArea.map((area, i) => (
                  <div key={i} className={style["container-information-area"]}>
                    <div className={style["informations-area"]}>
                      <div className={style["title-card"]}>{area.area}</div>
                      <div className={style["second-text"]}>
                        {area.first_responder ? area.first_responder : " "}
                      </div>
                      <div className={style["second-text"]}>
                        {area.first_response ? area.first_response : "Pendente"}
                      </div>
                    </div>
                    <div className={style["container-green-red"]}>
                      <div
                        className={
                          area.responded ? style["green"] : style["red"]
                        }
                      >
                        <div
                          className={
                            area.responded
                              ? style["green-ball"]
                              : style["red-ball"]
                          }
                        >
                          {area.responded ? (
                            <CheckCircleOutlinedIcon
                              style={{ width: "1.5vw" }}
                            />
                          ) : (
                            <ErrorOutlineOutlinedIcon
                              style={{ width: "1.5vw" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={style["grafic-line-relation"]}>
            <text className={style["title-white"]}>Relação de Usuários</text>
            <div className={style["legend"]}>
              <div>
                <div className={style["legend-title"]}>
                  <div className={style["cube-legend-interno"]}></div>
                  <text className={style["font-soft-legend"]}>Internos</text>
                </div>
                <div className={style["legend-title"]}>
                  <text className={style["font-heavy-legend"]}>
                    {percentualInternos}%
                  </text>
                </div>
              </div>
              <div>
                <div className={style["legend-title"]}>
                  <div className={style["cube-legend-externo"]}></div>
                  <text className={style["font-soft-legend"]}>Externo</text>
                </div>

                <div className={style["legend-title"]}>
                  <text className={style["font-heavy-legend"]}>
                    {percentualExternos}%
                  </text>
                </div>
              </div>
            </div>

            <div
              className={style["container-bar"]}
              style={{
                gridTemplateColumns: `${percentualInternos}% ${percentualExternos}%`,
              }}
            >
              <div className={style["bar-interno"]}></div>
              <div className={style["bar-externo"]}></div>
            </div>
          </div>

          <div className={style["grafic-line"]}>
            <text className={style["title-white"]}>
              Confirmações por Agentes
            </text>
            <div
              className="highlight-bar-charts"
              style={{
                userSelect: "none",
                width: "100%",
                display: "flex",
                height: "20vh",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={25}
                >
                  <XAxis type="number" tick={false} />
                  <YAxis
                    dataKey="name"
                    type="category"
                    tickFormatter={(value) => {
                      if (value === "1" || value === "0") return "Interno";
                      if (value === "2") return "Externo";
                      return value;
                    }}
                    tick={{ style: { fontSize: "0.6vw" } }}
                  />
                  <Tooltip content={<CustomTooltipBar />} />

                  <Bar dataKey="people" stackId="a">
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.name === "1" || entry.name === "0"
                            ? "#B8ABF8"
                            : "#DDD6FD"
                        }
                      />
                    ))}
                  </Bar>
                  <Bar dataKey="porcent" stackId="a" fill="#fff" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className={style["division-period"]}>
            <text className={style["title-white"]}>Período do Disparo</text>
            <div className={style["division-period-start-end"]}>
              <div className={style["container-dates"]}>
                <text className={style["title-white"]}>Início</text>
                <div className={style["containe-dates-text-icon"]}>
                  <div className={style["container-dates-text"]}>
                    <text className={style["font-soft"]}>{dataInicio}</text>
                    <text className={style["font-soft"]}>{horaInicio}</text>
                  </div>
                  <div>
                    <div className={style["cube-icon"]}>
                      <div className={style["circle-icon"]}>
                        <PlayCircleFilledWhiteOutlinedIcon
                          style={{ width: "1.5vw" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style["container-dates"]}>
                <text className={style["title-white"]}>Término Previsto</text>
                <div className={style["containe-dates-text-icon"]}>
                  <div className={style["container-dates-text"]}>
                    <text className={style["font-soft"]}>{dataInicio}</text>
                    <text className={style["font-soft"]}>{horaFinal}</text>
                  </div>
                  <div>
                    <div className={style["cube-icon"]}>
                      <div className={style["circle-icon"]}>
                        <PauseCircleOutlineOutlinedIcon
                          style={{ width: "1.5vw" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default BodyDataBody;
